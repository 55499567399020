import React from "react"
import theme from "theme"
import { Theme, Text, Box, Image, LinkBox, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"services"} />
      <Helmet>
        <title>Про Нас | гольф-клуб Greenfield</title>
        <meta
          name={"description"}
          content={"Тестуйте гольф в найкращих випадках"}
        />
        <meta
          property={"og:title"}
          content={"Про Нас |  гольф-клуб Greenfield"}
        />
        <meta
          property={"og:description"}
          content={"Тестуйте гольф в найкращих випадках"}
        />
        <meta
          property={"og:image"}
          content={"https://luxivance.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://luxivance.com/img/186176.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://luxivance.com/img/186176.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://luxivance.com/img/186176.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://luxivance.com/img/186176.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://luxivance.com/img/186176.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://luxivance.com/img/186176.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="80px 0 80px 0"
        lg-padding="60px 0 60px 0"
        md-padding="40px 0 40px 0"
        sm-padding="30px 0 30px 0"
      >
        <Box
          margin="0px 0px 32px 0px"
          display="flex"
          flex-direction="column"
          align-items="flex-start"
        >
          <Text
            font="--headline1"
            color="--dark"
            text-align="center"
            md-font='normal 700 42px/1.2 "Source Sans Pro", sans-serif'
            margin="16px 0px 0px 0px"
          >
            Послуги гольф-клубу Greenfield
          </Text>
          <Text font="--lead" color="--darkL2" padding="0px 0px 50px 0px">
            У гольф-клубі Greenfield ми прагнемо забезпечити винятковий досвід
            для всіх наших членів та гостей. Широкий спектр наших послуг
            покликаний задовольнити будь-які ваші потреби, гарантуючи, що час,
            проведений з нами, буде приємним і незабутнім. У гольф-клубі
            Greenfield є все, що потрібно, щоб покращити вашу гру та отримати
            максимум користі з вашого візиту: від першокласних умов до
            професійного навчання.
          </Text>
        </Box>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Сучасні тренувальні комплекси
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Полігон для водіння: Наш великий драйвінг-рейндж ідеально
                підходить для розминки перед раундом або роботи над своїм
                свінгом. Обладнаний безліччю мішеней та доглянутим газоном, він
                забезпечує реалістичні умови для тренувань.
                <br />
                <br />
                Грини для патчингу: Потренуйте свою коротку гру на наших
                ретельно доглянутих грінах. Ці майданчики повторюють умови
                нашого поля, що допоможе відточити свої навички.
                <br />
                <br />
                Зона для чіпінгу: Відточуйте техніку гри в чіпінг,
                використовуючи спеціальну зону для чіпінгу, обладнану бункерами
                та різноманітними доріжками для імітації умов на полі.
              </Text>
            </Box>
          </Box>
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://luxivance.com/img/7.jpg"
              border-radius="24px"
              max-width="100%"
              width="100%"
              object-fit="none"
              max-height="400px"
              sm-max-height="213px"
            />
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://luxivance.com/img/8.jpg"
              border-radius="24px"
              max-width="100%"
              width="100%"
              object-fit="none"
              max-height="400px"
              sm-max-height="213px"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Ексклюзивні переваги для членів клубу
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Пріоритетний час гри: Члени клубу мають пріоритетний доступ до
                таймів, що дозволяє грати у зручний для вас час.
                <br />
                <br />
                Заходи лише для членів клубу: Беріть участь у ексклюзивних
                турнірах, соціальних заходах та іграх ліги, спрямованих на
                розвиток спільноти та товариства серед наших членів.
                <br />
                <br />
                Знижки та пропозиції: Скористайтеся спеціальними знижками на
                товари з магазину, ресторани та інші послуги клубу.
              </Text>
            </Box>
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Розкішні зручності у клубному будинку
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Вишукана кухня: Насолоджуйтеся смачною їжею у нашому елегантному
                ресторані у клубі. У нашому меню представлені різноманітні
                вишукані страви, виготовлені з найсвіжіших інгредієнтів.
                <br />
                <br />
                Розслаблююча вітальня: Відпочиньте після гри в нашій затишній
                вітальні, де ви можете насолодитися напоєм і поділитися
                історіями з колегами з гольфу.
                <br />
                <br />
                Роздягальні: Наші просторі та добре обладнані роздягальні – це
                місце, де можна відпочити та освіжитися, з усіма необхідними
                зручностями.
              </Text>
            </Box>
          </Box>
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://luxivance.com/img/9.jpg"
              border-radius="24px"
              max-width="100%"
              width="100%"
              object-fit="none"
              max-height="400px"
              sm-max-height="213px"
            />
          </Box>
        </LinkBox>
        <LinkBox
          display="flex"
          margin="0px 0px 32px 0px"
          md-flex-direction="column"
          md-align-items="center"
          md-justify-content="center"
          flex-direction="row"
          align-items="flex-start"
          flex-wrap="wrap"
          lg-margin="0px 0px 16px 0px"
        >
          <Box
            width="50%"
            padding="0px 16px 0px 0px"
            align-items="flex-start"
            display="flex"
            justify-content="flex-start"
            margin="0px 0px 0px 0px"
            md-width="100%"
            lg-width="100%"
            lg-padding="0px 0px 0px 0px"
          >
            <Image
              src="https://luxivance.com/img/10.jpg"
              border-radius="24px"
              max-width="100%"
              width="100%"
              object-fit="none"
              max-height="400px"
              sm-max-height="213px"
            />
          </Box>
          <Box
            width="50%"
            padding="0px 0px 0px 16px"
            display="flex"
            align-items="flex-start"
            justify-content="flex-start"
            md-width="100%"
            sm-align-items="flex-start"
            sm-flex-direction="column"
            sm-justify-content="flex-start"
            flex-direction="column"
            lg-width="100%"
            lg-flex-direction="row"
            lg-justify-content="space-between"
            lg-padding="16px 12px 16px 12px"
          >
            <Box
              padding="0px 50px 0px 0px"
              lg-width="70%"
              sm-width="100%"
              lg-padding="0px 0px 0px 0px"
            >
              <Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
                Проведення заходів та приватні заходи
              </Text>
              <Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
                Корпоративні заходи: Проведіть свій наступний корпоративний
                захід у гольф-клубі Greenfield. Наша команда буде працювати з
                вами, щоб створити незабутні враження для ваших гостей.
                <br />
                <br />
                Весілля та урочистості: Наша красива територія та елегантний
                клубний будинок - ідеальне тло для проведення весіль та
                особливих урочистостей. Дозвольте нам допомогти вам спланувати
                незабутню подію.
                <br />
                <br />
                Турніри з гольфу: Організуйте для своєї групи індивідуальний
                турнір з гольфу з підрахунком очок, призами та кейтерингом.
              </Text>
            </Box>
          </Box>
        </LinkBox>
      </Section>
      <Section
        padding="80px 0 80px 0"
        sm-padding="60px 0 60px 0"
        background="--color-primary"
      >
        <Override
          slot="SectionContent"
          flex-direction="row"
          md-flex-wrap="wrap"
        />
        <Box
          width="45%"
          display="flex"
          flex-direction="column"
          justify-content="space-between"
          md-width="100%"
          padding="0px 80px 0px 0px"
          lg-padding="0px 30px 0px 0px"
          md-padding="0px 0px 0px 0px"
        >
          <Text
            margin="0px 0px 0px 0px"
            color="--light"
            font="--headline2"
            md-margin="0px 0px 30px 0px"
          >
            Зв'яжіться з нами
          </Text>
        </Box>
        <Box
          width="55%"
          display="flex"
          flex-direction="column"
          justify-content="space-between"
          md-width="100%"
        >
          <Text margin="0px 0px 40px 0px" color="--light" font="--base">
            І це лише початок. Зв'яжіться з нами сьогодні та дізнайтесь про всі
            наші пропозиції. Якщо ви бажаєте стати членом клубу або плануєте
            наступний захід, наша команда готова допомогти вам. Зв'яжіться з
            нами, щоб дізнатися більше про те, як гольф-клуб Greenfield може
            покращити ваш досвід гри в гольф.
            <br />
            <br />
            Ми будемо раді вітати вас у гольф-клубі Greenfield та допомогти вам
            отримати максимум користі з вашого перебування у нас.
          </Text>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
